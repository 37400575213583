<template>
  <VueContentHolder id="divLoginMainWrapper">
    <VueMediaContentBox :mediaSrc="bgUrl" aspectRatios="14by9">
      <div class="mediabox-content mediabox-top-content" slot="top">
        <img src="~@/assets/logos/pmaktif-alt.png" alt="welcome-logo" />
      </div>
      <div class="mediabox-content mediabox-bottom-content" slot="bottom">
        <VueHeadline level="1" weightLevel="4">{{ $t('homePage.body.title') }}</VueHeadline>
        <VueText sizeLevel="6">{{ $t('homePage.body.text') }}</VueText>
      </div>
    </VueMediaContentBox>
    <VueContentHolder :padding="[10, 20, 0, 20]">
      <VueListView dir="column" :isInline="false">
        <VueContentHolder :padding="[10, 0, 0]">
          <VueButton
            :size="sizes.xLarge"
            color="default"
            :outlined="true"
            as="router-link"
            :target="routes.login.WithPhoneNumber.path"
            :iconName="icons.mobile.name"
            :iconSize="{
              width: icons.mobile.width,
              height: icons.mobile.height,
            }"
            >{{ $t('homePage.buttons.phoneText') }}</VueButton
          >
        </VueContentHolder>
        <VueContentHolder :padding="[10, 0, 0]">
          <VueButton
            :size="sizes.xLarge"
            color="default"
            :outlined="true"
            as="router-link"
            :target="routes.login.WithCustomerNumber.path"
            :iconName="icons.store.name"
            :iconSize="{
              width: icons.store.width,
              height: icons.store.height,
            }"
            >{{ $t('homePage.buttons.customerNumberText') }}</VueButton
          >
        </VueContentHolder>
        <VueContentHolder :padding="[10, 0, 0]">
          <VueButton
            :size="sizes.xLarge"
            color="default"
            :outlined="true"
            as="router-link"
            :target="routes.login.WithTaxpayerIdNumber.path"
            :iconName="icons.envelop.name"
            :iconSize="{
              width: icons.envelop.width,
              height: icons.envelop.height,
            }"
            >{{ $t('homePage.buttons.vknNumberText') }}</VueButton
          >
        </VueContentHolder>
        <VueContentHolder :padding="[10, 0, 0, 0]">
          <div
            style="display: flex; flex-wrap: wrap; justify-content: end;"
            class="first-login-button-holder"
          >
            <VueButton
              color="white"
              as="router-link"
              :target="`${routes.login.FirstLogin.path}?header=3`"
              :contentAlignment="constants.flexAlignment.end"
              style="color: #23303d"
              >{{ $t('homePage.buttons.firstLoginText') }}</VueButton
            >
            <button id="ot-sdk-btn" class="ot-sdk-show-settings cookie-setting">
              Çerez Ayarları
            </button>
          </div>
        </VueContentHolder>
      </VueListView>
    </VueContentHolder>
    <div class="landing-footer">
      <BrandLogo
        :hasBorder="false"
        :width="111"
        :height="60"
        :image="require('@/assets/logos/philip-morris-logo.png')"
      />
      <div class="footer-right-area">
        <VueText sizeLevel="3" weightLevel="1" color="grey-30" class="footer-text"
          >PMAktif portal, tacir/esnaf niteliği taşıyan yalnızca Türkiye’deki satış noktalarına
          yönelik olarak hazırlanmıştır. Siteyi kullanmak istiyorsanız, satış temsilciniz ile
          iletişime geçiniz.</VueText
        >
        <div class="footer-link-holder">
          <VueText
            sizeLevel="3"
            weightLevel="3"
            color="grey-30"
            class="footer-left-link"
            @click="openPlainTextModal(1)"
            >Yasal Şartlar</VueText
          >
          <VueText
            sizeLevel="3"
            weightLevel="3"
            color="grey-30"
            class="footer-right-link"
            @click="openPlainTextModal(2)"
            >Gizlilik Bildirimi</VueText
          >
        </div>
      </div>
    </div>
    <div>
      <VueText sizeLevel="3" weightLevel="3" color="grey-30" style="padding-left: 10px"
        >v {{ getVersion }}</VueText
      >
    </div>

    <BrandSelectModal
      :showModal="isLegalWarningModalOpened"
      :title="'Yasal Şartlar'"
      :plainTextModal="true"
      @onModalClose="closePlainTextModal"
      @click="closePlainTextModal"
    >
      <VueContentHolder :padding="[20, 20, 80, 20]">
        <VueText sizeLevel="4" color="grey-80" weightLevel="3" v-html="modalContent"></VueText>
      </VueContentHolder>
    </BrandSelectModal>

    <BrandSelectModal
      :showModal="isDataPolicyModalOpened"
      @onModalClose="closePlainTextModal"
      :title="'Gizlilik Bildirimi'"
      :plainTextModal="true"
      @click="closePlainTextModal"
    >
      <VueContentHolder :padding="[20, 20, 80, 20]">
        <VueText sizeLevel="4" color="grey-80" weightLevel="3" v-html="modalContent"></VueText>
      </VueContentHolder>
    </BrandSelectModal>
    <BrandModal />
  </VueContentHolder>
</template>
<script>
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueMediaContentBox from '@/components/shared/VueMediaContentBox/VueMediaContentBox.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import StaticContent from '@/services/Api/staticContents';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import { version } from '@/../package.json';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'LoginTypeSelection',
  components: {
    VueButton,
    VueListView,
    VueMediaContentBox,
    VueText,
    VueHeadline,
    VueContentHolder,
    BrandSelectModal,
    BrandLogo,
    BrandModal,
  },
  data() {
    return {
      bgUrl: `url(${require('@/assets/images/login.png')})`,
      modalContent: '',
      isLegalWarningModalOpened: false,
      isDataPolicyModalOpened: false,
    };
  },
  mounted() {
    let logoutInfo = new StorageHelper({ id: process.env.VUE_APP_STORAGE_KACC_LOGOUT_INFO }).get();

    if (logoutInfo) {
      setTimeout(() => {
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_KACC_LOGOUT_INFO }).remove();
        let resultModalConfig = {
          isModalOpened: true,
          modalType: 'alert',
          noIcon: true,
          firstButtonText: 'TAMAM',
          modalText:
            'Şirkette çalışmadığınızı  belirttiğiniz için oturumunuz kapatıldı ve kullanıcınız silindi.',
        };
        this.$store.dispatch('app/setModalConfig', resultModalConfig);
      }, 100);
    }
  },
  computed: {
    routes() {
      return {
        login: RoutesLogin,
      };
    },
    getVersion() {
      return version;
    },
    icons() {
      return COMPONENT_CONSTANTS.ICON_VARIABLES;
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
  methods: {
    closePlainTextModal() {
      this.modalContent = '';
      this.isLegalWarningModalOpened = false;
      this.isDataPolicyModalOpened = false;
    },
    openPlainTextModal(modalType) {
      switch (modalType) {
        case 1:
          return this.getStaticContent(modalType), (this.isLegalWarningModalOpened = true);
        case 2:
          return this.getStaticContent(modalType), (this.isDataPolicyModalOpened = true);
      }
    },
    getStaticContent(type) {
      let staticContentType = '';
      if (type === 1) {
        staticContentType = 'YasalUyari';
      } else if (type === 2) {
        staticContentType = 'VeriPolitikalari';
      }
      StaticContent.getPublicStaticContent(staticContentType).then(res => {
        if (res.data.Data) {
          this.modalContent = res.data.Data.content;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/styles';
.mediabox-content {
  padding: 0 palette-space-level('20');
  color: $brand-text-secondary-color;

  &.mediabox-top-content {
    text-align: left;
    padding-top: palette-space-level('35');
    img {
      transform: scale(1.2);
      transform-origin: left;
    }
  }
  &.mediabox-bottom-content {
    h1 {
      padding-bottom: palette-space-level('12');
    }
    p {
      padding-bottom: palette-space-level('20');
    }
  }
}

.modal-backdrop {
  line-height: 1.86;
}
.landing-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding: palette-padding-level(0, 20, 30, 20);
  width: 100%;

  .footer-right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 18px;

    .footer-text {
      line-height: 1.27;
    }

    .footer-link-holder {
      display: flex;
      flex-direction: row;
      align-items: space-between;

      .footer-left-link {
        padding-right: palette-space-level('30');
      }
      .footer-left-link,
      .footer-right-link {
        padding-top: palette-space-level('10');
        line-height: 1.27;
        text-decoration: underline;
      }
    }
  }
}
.cookie-setting {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  margin-right: 6%;
}
</style>
